import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import { Avatar } from '~/core/ui/Avatar'
import { Badge, IColorBadgeType } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { defaultFormatDate } from '~/core/utilities/format-date'
import { formatMoneyWithComma } from '~/lib/features/placements/utilities'
import { PLACEMENT_COLOR_STATUS } from '~/lib/features/placements/utilities/enum'
import createModalHook from '~/lib/hooks/create-modal-hooks'
import useBoundStore from '~/lib/store'
import { IPlacement } from '../placement'
import { useEditPlacementModel } from './EditPlacementDialog'
import ToolTipOnOverflow from './ToolTipOnOverflow'

const RowInfoWrapper: FC<{
  hide?: boolean
  nameIcon: LucideIconName
  labelName: string
  content: string | ReactNode
  classNameLeftView?: string
}> = ({ nameIcon, labelName, content, classNameLeftView, hide = false }) => {
  return !hide ? (
    <div className="mt-3 grid w-full grid-cols-1 gap-[2px] tablet:grid-cols-[132px_1fr] tablet:gap-4">
      <div
        className={`flex flex-row items-center self-start ${
          classNameLeftView || ''
        }`}>
        <IconWrapper size={16} className="text-gray-500" name={nameIcon} />
        <TypographyText className="ml-2 text-sm font-normal text-gray-600">
          {labelName}
        </TypographyText>
      </div>
      <div className="text-sm text-gray-900 tablet:w-full">{content}</div>
    </div>
  ) : (
    <></>
  )
}
type OpenParams = {
  placement: IPlacement
  header: { candidateName?: string; jobTitle?: string; companyName?: string }
  onPlacementEdited: (placement: IPlacement) => Promise<any>
}
const PlacementDetailDialog = ({
  open,
  setOpen,
  openParams
}: {
  open: boolean
  setOpen: (open: boolean, param?: OpenParams) => void
  openParams?: OpenParams
}) => {
  const { t, i18n } = useTranslation()
  const placement = openParams?.placement
  const { EditPlacementModalComponent, openEditPlacementModel } =
    useEditPlacementModel()
  const placementStatus = useEnumsData({
    enumType: 'PlacementStatus',
    locale: i18n.language
  })
  const { setToast } = useBoundStore()

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      isDivider={true}
      isPreventAutoFocusDialog={true}
      size="sm"
      label={`${t('placements:placementDetail')}`}
      description={
        <div>
          <EditPlacementModalComponent />
          <div className="flex items-center">
            <div className="mr-2">
              <IconWrapper size={16} name="User" />
            </div>
            <Tooltip content={openParams?.header?.candidateName}>
              <div className="line-clamp-1 text-sm text-gray-900">
                {openParams?.header?.candidateName}
              </div>
            </Tooltip>
          </div>
          <div className="mt-1.5 flex items-center">
            <div className="mr-2 shrink-0">
              <IconWrapper size={16} name="Briefcase" />
            </div>
            <ToolTipOnOverflow
              text={openParams?.header?.jobTitle}
              className="max-w-[200px] shrink-0 truncate text-sm text-gray-900"
            />
            <div className="ml-2 mr-2 h-0.5 w-0.5 shrink-0  rounded-full bg-gray-400"></div>
            <ToolTipOnOverflow
              className="shrink-1 truncate"
              text={openParams?.header?.companyName}
            />
          </div>
        </div>
      }>
      {openParams?.placement && (
        <div>
          <RowInfoWrapper
            hide={!placement?.status}
            nameIcon="Zap"
            labelName={`${t('placements:management:table:placement_status')}`}
            content={
              <div className="flex text-sm text-gray-900">
                <Badge
                  type="dotLeading"
                  color={
                    PLACEMENT_COLOR_STATUS(placement?.status as string)
                      .color as IColorBadgeType
                  }>
                  {
                    placementStatus.find(
                      (item: ISelectOption) => item.value === placement?.status
                    )?.supportingObj?.name
                  }
                </Badge>
              </div>
            }
          />
          <RowInfoWrapper
            hide={!placement?.hiredDate}
            nameIcon="CalendarCheck2"
            labelName={`${t('placements:management:table:hired_date')}`}
            content={
              <div className="text-sm text-gray-900">
                {placement?.hiredDate &&
                  defaultFormatDate(new Date(placement?.hiredDate))}
              </div>
            }
          />
          <RowInfoWrapper
            hide={!placement?.onboardDate && !placement?.endOfProbationDate}
            nameIcon="Calendar"
            labelName={`${t('placements:management:table:start_end_date')}`}
            content={
              <div className="text-sm text-gray-900">
                {placement?.onboardDate &&
                  defaultFormatDate(new Date(placement?.onboardDate))}{' '}
                {placement?.endOfProbationDate &&
                  `- ${defaultFormatDate(
                    new Date(placement?.endOfProbationDate)
                  )}`}
              </div>
            }
          />
          <RowInfoWrapper
            hide={!placement?.salary}
            nameIcon="DollarSign"
            labelName={`${t('placements:management:table:salary')}`}
            content={
              <div className="break-all text-sm text-gray-900">
                {formatMoneyWithComma(Number(placement?.salary))}{' '}
                {placement?.currencyOfSalary}{' '}
                <span className="text-sm  text-gray-500">
                  ({placement?.typeOfSalary})
                </span>
              </div>
            }
          />
          <RowInfoWrapper
            hide={!placement?.fee}
            nameIcon="Scroll"
            labelName={`${t('placements:management:table:fee')}`}
            content={
              <div className="break-all text-sm text-gray-900">
                {{
                  percentage: `${formatMoneyWithComma(
                    Number(placement?.fee)
                  )} %`,
                  months: `${t('placements:fee_months', {
                    num: `${formatMoneyWithComma(Number(placement?.fee))}`
                  })}`
                }[placement?.typeOfFee as string] ||
                  `${formatMoneyWithComma(Number(placement?.fee))} ${
                    placement?.currencyOfRevenue
                  }`}
              </div>
            }
          />
          <RowInfoWrapper
            hide={!placement?.revenue}
            nameIcon="DollarSign"
            labelName={`${t('placements:management:table:revenue')}`}
            content={
              <div className="break-all text-sm text-gray-900">
                {formatMoneyWithComma(Number(placement?.revenue))}{' '}
                {placement?.currencyOfRevenue}
              </div>
            }
          />
          <RowInfoWrapper
            hide={!placement?.profitSplits?.length}
            nameIcon="Users"
            labelName={`${t('placements:management:table:profit_split')}`}
            content={placement?.profitSplits?.map((split, index) => (
              <div key={index} className="mb-2">
                <div className="flex items-center">
                  <Avatar
                    size="xs"
                    color={split?.user?.defaultColour}
                    src={split?.user?.avatarVariants?.thumb?.url}
                    alt={split?.user?.fullName}
                  />
                  <Tooltip content={split?.user?.fullName}>
                    <div className="ml-2 line-clamp-1 text-sm font-medium text-gray-900">
                      {split?.user?.fullName}
                    </div>
                  </Tooltip>
                  <div className="ml-2 h-1 w-1 animate-bounce rounded-full bg-gray-400"></div>
                  <div className="ml-2 text-sm  font-medium  text-gray-900">
                    {split?.profitPercentage}%
                  </div>
                </div>
              </div>
            ))}
          />
          <div className="flex justify-end pt-4">
            <Button
              icon="leading"
              iconMenus="Edit"
              label={`${t('button:edit')}`}
              type="primary"
              size="sm"
              onClick={() => {
                openEditPlacementModel({
                  header: {
                    candidateName: openParams?.header?.candidateName,
                    jobTitle: openParams?.header?.jobTitle,
                    companyName: openParams?.header?.companyName
                  },
                  placement: openParams?.placement,
                  onPlacementEdited: (placement) => {
                    setOpen(true, { ...openParams, placement })
                    openParams?.onPlacementEdited(placement)
                    setToast({
                      open: true,
                      type: 'success',
                      title: `${t('notification:placementUpdated')}`
                    })
                  }
                })
                // setOpen(false)
              }}
            />
          </div>
        </div>
      )}
    </Dialog>
  )
}
export const useOpenPlacementDetailDialog = () => {
  const {
    ModalComponent: PlacementDetailModalComponent,
    openModal: openPlacementDetailModel
  } = createModalHook(PlacementDetailDialog)((data) => {
    return Promise.resolve()
  })

  return {
    PlacementDetailModalComponent,
    openPlacementDetailModel
  }
}
